
import { FieldValue, HTMLExcerpt, DaysAgoFieldValue, } from "../components/carbon-entitysync";
import { FormLabel, Tile } from 'carbon-components-react';
import { Location24, Enterprise24} from "@carbon/icons-react";
import { EntityContext, Field, FieldContext } from "@parallelpublicworks/entitysync";
import { Link } from "gatsby";
import React, { useContext } from 'react';
import { getAddress, getSalary, slugify } from './../util';
import Tag from './Tag'

function FieldAddress(){
  function AddressComponent(){
    const context = useContext(FieldContext);
    const address = context && context[0] ? getAddress(context[0]) : null;
    return <>{address}</>
  }
  return (
    <Field field="field_address">
      <AddressComponent/>
    </Field>
  );
}

function FieldSalary({salaryTypes}){
  function findSalaryType(id) {
    return salaryTypes ? salaryTypes.find((type) => type.drupal_id === id) || null : null;
  }
  const {data} = useContext(EntityContext);
  let num = data.attributes.field_salary_number ? Math.abs(data.attributes.field_salary_number) : null;
  let num_max = data.attributes.field_salary_number_max ? Math.abs(data.attributes.field_salary_number_max) : null;
  let type = data.relationships.field_salary_type?.data ? findSalaryType(data.relationships.field_salary_type.data.id) : null;
  return num && type ? <p><Tag label={getSalary(num, type, num_max)} /></p> : <></>;
}

function FieldApply({applicationMethods}) {
  function findApplicationMethod(id) {
    return applicationMethods ? applicationMethods.find((method) => method.drupal_id === id) || null : null;
  }
  const {data} = useContext(EntityContext);
  let method = data.relationships.field_application_method ? findApplicationMethod(data.relationships.field_application_method.id) : null;
  return method ? <>{` - ${method.name}`}</> : <></>;
}

export default function ListingSummary({salaryTypes, applicationMethods, expired}){
  const {data} = useContext(EntityContext);
  if(!data.attributes || (data.attributes && data.save)) return <></>;
  const id = data.id ? data.id : data.local_id;
  const slug = slugify(data.attributes.title);
  let url = data.local_id ? `/preview?id=${data.local_id}` : expired || (typeof data.attributes.changed === 'number' && data.attributes.created) ? `/preview?id=${data.id}`  : `/job-search/${slug}-${id}`;
  if(data.gatsbyPublished){
    url = `/job-search/${slug}-${id}`;
  }
  if(!slug) return <></>;
  return (
    <Tile key={id} className="search-result" light>
      <div className="listing-summary">
        <h2><Link to={url}><FieldValue field="title"/></Link></h2>
        <p className="meta-data"><Enterprise24 /> <span><FieldValue field="field_company_name"/></span> <Location24 /> <span><FieldAddress /></span></p>
        <FieldSalary salaryTypes={salaryTypes} />
        <HTMLExcerpt field="field_required_qualifications" title="Requirements" />
        <FormLabel><DaysAgoFieldValue maxDaysAgo={14} field="field_publish_date" /> <FieldApply applicationMethods={applicationMethods} /></FormLabel>
      </div>
    </Tile>
  );
}