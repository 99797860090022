import React from 'react'
import {Entity, UserContext, fetchAuthenticatedContent, handleLogout} from '@parallelpublicworks/entitysync'
import {Tile, ButtonSet} from 'carbon-components-react'
import { InlineNotification } from 'gatsby-theme-carbon';
import ListingSummary from "../../components/listing-summary"
import {getUserListings} from '../../util'
import {graphql, navigate} from 'gatsby';
import { SubmitButton } from '../../components/carbon-entitysync';


export default function Expired({data, pageContext, location}) {
    const [loading, setLoading] = React.useState(false)
    const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
    const userContext = React.useContext(UserContext);
    
    React.useEffect(() => {
      if (loading) {
        setUserDataChangesCount(userDataChangesCount + 1)
      }
    }, [userContext, loading])
    
    React.useEffect(() => {
      if (userDataChangesCount >= 3) {
        //all saved
        setLoading(false)
        setUserDataChangesCount(0)
      }
    }, [userDataChangesCount])

    
    if(!location || ! Array.isArray(userContext) || userContext.length !== 2){
      return (
        <></>
      )
    }

    function pingFn(){
      return fetchAuthenticatedContent(userContext[0].auth, userContext[1], 'node/article', 'GET')
      .then(resp => {
        return resp
      })
    }

    function RepublishButton({children}){
        async function onSubmit(e, unsavedChanges){
          setLoading(true)
          const ping = await pingFn()
          if(!ping){
            e.preventDefault();
            console.error('ping ERROR, logging out...');
            handleLogout(userContext);
            navigate('/login')
          }else{
            console.log('ping SUCCESS, continue saving...');
          }
          if(!unsavedChanges.attributes) unsavedChanges.attributes = {};
          unsavedChanges.attributes.field_publish_date = Math.round(new Date().getTime() / 1000);
          unsavedChanges.attributes.field_expired = false;

        }
        return (
          <SubmitButton onSubmit={onSubmit} disabled={loading}>
            Republish
          </SubmitButton>
        );
    }
    function DeleteButton({id}){
        async function onSubmit(e, unsavedChanges){
          setLoading(true)
          const ping = await pingFn()
          if(!ping){
            e.preventDefault();
            console.error('ping ERROR, logging out...');
            handleLogout(userContext);
            navigate('/login')
          }else{
            console.log('ping SUCCESS, continue saving...');
          }
          if(!unsavedChanges.attributes) unsavedChanges.attributes = {};
          unsavedChanges.attributes.field_archived = true;
        }
        return (
          <SubmitButton kind="secondary" onSubmit={onSubmit} disabled={loading}>Delete</SubmitButton>
        )
    }

    const [{userData, currentUserId}] = userContext;
    const expiredListings = getUserListings(userData, data, currentUserId, true);

    return (
        <div>
          <>
              {
                 expiredListings.length > 0 && expiredListings.map(({id}) => {
                    const source = id.startsWith('local') ? { local_id: id } : { id: id };
                    return (
                        <div key={id}>
                            <Entity source={source} type="node--job_listing" componentId={id}>
                                <ListingSummary expired={true} />
                                <Tile style={{marginBottom: 40}}>
                                  <ButtonSet>
                                      <DeleteButton id={id}/>
                                      <RepublishButton/>
                                  </ButtonSet>
                                </Tile>
                            </Entity>
                        </div>
                    );
                  })
              }
              {
                expiredListings.length === 0 && <InlineNotification className="inline-notification" kind="info"><strong>You have no expired job listings.</strong> </InlineNotification>
              }
          </>
        </div>
    )
}


export const query = graphql`
query ExpiredListingsQuery {
  allNodeJobListing(sort: {fields: field_publish_date, order: DESC}, filter: {changed: {gt: "2022-01-01"}, field_publish_date: {gt: "2022-01-01"}}) {
    nodes {
      drupal_id
      changed
      title
      field_expired
      field_publish_date
      field_archived
      field_company_name
      field_preferred_qualifications {
        value
      }
      field_required_qualifications {
        value
      }
      field_description {
        value
      }
      field_benefits {
        value
      }
      field_address {
        address_line1
        administrative_area
        locality
        postal_code
      }
      field_salary_number
      field_salary_number_max
      relationships {
        uid {
          drupal_id
        }
        field_category {
          drupal_id
          name
        }
        field_experience_level {
          drupal_id
          name
        }
        field_position {
          drupal_id
          name
        }
        field_salary_type {
          drupal_id
          name
          description {
            processed
          }
        }
        field_expiration {
          drupal_id
          name
        }
        field_application_method {
          drupal_id
          name
        }
        field_education {
          drupal_id
          name
        }
        field_bilingual {
          drupal_id
          name
        }
        field_experience_years {
          drupal_id
          name
        }
      }
      field_required_documents
      field_application_url {
        uri
      }
    }
  }
  allTaxonomyTermExpirationLengths {
    nodes {
      drupal_id
      name
    }
  }
}

`;
